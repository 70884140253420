import React, { useEffect, useState } from "react";
import { Box, Button, FormControl, Typography } from "@mui/material";
import { CollectionPoint, VenueSection } from "../../types";
import { Text } from "../../elements/text";
import { ConfirmationDailog } from "../../elements/confirmation-dialog";
import { v4 } from "uuid";
import SectionInput from "./section-input";

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: any;
  id?: string;
  title?: string;
  isMandatory?: boolean;
  sections?: VenueSection[];
  collectionPoints: CollectionPoint[];
  setCollectionPoints: React.Dispatch<
    React.SetStateAction<CollectionPoint[] | undefined>
  >;
}

export const VenueSections = (props: Props) => {
  const [sections, setSections] = useState<VenueSection[]>(
    props.sections || []
  );
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [selectedSectionId, setselectedSectionId] = useState<string | null>();

  useEffect(() => {
    props.onChange(sections);
  }, [sections]);

  const deleteSection = (sectionId: string | null | undefined) => {
    if (!sectionId) return;
    const newSections: VenueSection[] = sections.map((section) => {
      if (section.id === sectionId) {
        section.isDeleted = true;
      }
      return section;
    });
    setSections(newSections);
  };

  const breakSectionCollectionPointAssociation = (
    sectionId: string | null | undefined
  ) => {
    if (!sectionId) return;
    const newCollectionPoints = props.collectionPoints.map(
      (collectionPoint) => {
        const filteredServiceableSectionsIds: string[] =
          collectionPoint.serviceableSectionsIds?.filter(
            (id) => id !== sectionId
          ) || [];
        collectionPoint.serviceableSectionsIds = filteredServiceableSectionsIds;
        return collectionPoint;
      }
    );
    props.setCollectionPoints(newCollectionPoints);
  };

  const onClickDelete = (id: string) => {
    let isAssociatedWithCollectionPoint = false;
    for (const collectionPoint of props.collectionPoints) {
      if (collectionPoint.serviceableSectionsIds?.includes(id)) {
        isAssociatedWithCollectionPoint = true;
        break;
      }
    }
    if (isAssociatedWithCollectionPoint) {
      setselectedSectionId(id);
      setShowConfirmationDialog(true);
    } else {
      deleteSection(id);
    }
  };

  return (
    <FormControl fullWidth sx={{ marginBottom: "16px" }}>
      <ConfirmationDailog
        open={showConfirmationDialog}
        title={`Delete collection-point association?`}
        text={
          "This section is associated with one or more collection points, are you sure you want to delete it?"
        }
        handleCancel={() => setShowConfirmationDialog(false)}
        handleContinue={() => {
          breakSectionCollectionPointAssociation(selectedSectionId);
          deleteSection(selectedSectionId);
          setShowConfirmationDialog(false);
        }}
      />
      <Box display="flex" mb={2}>
        <Box pt={0.5} mr={2} sx={{ display: "flex", flexDirection: "row" }}>
          <Text text={props.title} />
          {props.isMandatory && (
            <>
              <Text fontSize={17} color="red" text={"*"} />
            </>
          )}
        </Box>
      </Box>

      {sections.map((section, index: number) => {
        if (section.isDeleted) return;
        return (
          <SectionInput
            key={section.id}
            sections={sections}
            section={section}
            index={index}
            setSections={setSections}
            onClickDelete={onClickDelete}
          />
        );
      })}
      <Box display={"flex"} mb={2} flexDirection={"column"}>
        <Button
          variant="outlined"
          style={{
            textTransform: "none",
          }}
          onClick={() => {
            setSections([
              ...sections,
              {
                label: "",
                id: v4(),
                isClickAndCollect: true,
                serviceMethods: [],
              } as VenueSection,
            ]);
          }}
        >
          Add Section
        </Button>
      </Box>
    </FormControl>
  );
};
