import { Box } from "@mui/system";
import React, { useContext, useState } from "react";
import { DateRangePicker } from "react-date-range";
import { CustomButton } from "../elements/button";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { GET_RECONCILIATION_REPORT } from "../graphql/query";
import { AppContext } from "../context/app-context";
import { useQueryHook } from "../hooks/use-query.hook";
import { Alert, Autocomplete, Grid, Snackbar, TextField } from "@mui/material";
import { CustomSelect, MenuItemType } from "../elements/select";
import { reportFilterConfig } from "../constants/constants";
import { ReportFilterType } from "../types";
import { extractAllErrorMessages } from "../utils/graphql";
import { useSnackbar } from "notistack";
import { Text } from "../elements/text";

export const ReconciliationPage = () => {
  const current = new Date();
  current.setHours(0);
  current.setMinutes(0);
  current.setSeconds(0);

  const appContext = useContext(AppContext);
  const [dateRange, setDateRange] = useState<any>([
    {
      startDate: current,
      endDate: current,
      key: "selection",
    },
  ]);
  const [isRequested, setIsRequested] = useState<boolean>(false);
  const [reportFilterType, setReportFilterType] = useState<string>(
    ReportFilterType.ADMIN
  );
  const [reportFilterValueIDs, setReportFilterValueIDs] = useState<string[]>(
    []
  );
  const { enqueueSnackbar } = useSnackbar();

  const { fetch: getReport, loading } = useQueryHook({
    query: GET_RECONCILIATION_REPORT,
    skipInitialCall: true,
    onCompleted: () => {
      console.log(appContext?.adminID?.value);
      setIsRequested(true);
      enqueueSnackbar(
        `${reportFilterConfig[reportFilterType].label} report successful.`,
        {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        }
      );
    },
    onError: (err) => {
      enqueueSnackbar(extractAllErrorMessages(err), {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    },
  });

  const reportTypeSelectData = Object.keys(reportFilterConfig).map(
    (filterType) => {
      const menuItem: MenuItemType = {
        value: filterType,
        label: reportFilterConfig[filterType].label,
      };
      return menuItem;
    }
  );

  const getFilterValuesByType = (): any[] => {
    const filterType = reportFilterType;
    const { filterValueQuery, filterValueQueryName } =
      reportFilterConfig[filterType];
    const { data } = useQueryHook({ query: filterValueQuery });
    // console.log({ data })
    return data?.[filterValueQueryName] || [];
  };

  const getSelectedFilterValues = (): any[] => {
    const reportFilterValues = getFilterValuesByType();
    if (reportFilterType === ReportFilterType.ADMIN) {
      return reportFilterValues.filter((reportFilterValue) =>
        reportFilterValueIDs.includes(reportFilterValue.id)
      );
    } else {
      return reportFilterValues.filter((reportFilterValue) =>
        reportFilterValueIDs.includes(reportFilterValue._id)
      );
    }
  };

  const getAdminReport = () => {
    setIsRequested(false);
    const rangeEnd = new Date(dateRange[0]?.endDate);
    rangeEnd.setDate(rangeEnd.getDate() + 1);
    getReport({
      reportType: reportFilterType,
      adminId: appContext?.adminID?.value,
      filters: {
        startDate: dateRange[0]?.startDate,
        endDate: rangeEnd,
        ...(ReportFilterType.ADMIN === reportFilterType
          ? {
              venueFilter: {
                ids: reportFilterValueIDs,
              },
            }
          : {}),
        ...(ReportFilterType.VENDOR === reportFilterType
          ? {
              vendorFilter: {
                ids: reportFilterValueIDs,
              },
            }
          : {}),
        ...(ReportFilterType.RUNNER === reportFilterType
          ? {
              runnerFilter: {
                ids: reportFilterValueIDs,
              },
            }
          : {}),
        ...(ReportFilterType.RUNNER_EARNINGS === reportFilterType
          ? {
              runnerFilter: {
                ids: reportFilterValueIDs,
              },
            }
          : {}),
        ...(ReportFilterType.VENDOR_ORDER_STATUS === reportFilterType
          ? {
              vendorOrderStatusFilter: {
                ids: reportFilterValueIDs,
              },
            }
          : {}),
      },
    });
  };

  return (
    <>
      <Grid container xs={"auto"} justifyContent={"center"} flexWrap={"wrap"}>
        <Grid item xs={"auto"} marginTop={4}>
          <DateRangePicker
            months={1}
            ranges={dateRange}
            direction="horizontal"
            rangeColors={[]}
            maxDate={current}
            onChange={(item) => {
              setDateRange([item.selection]);
            }}
          />
        </Grid>
        <Grid item xs={4} marginTop={4} marginLeft={4}>
          <CustomSelect
            required
            data={reportTypeSelectData}
            onChange={(value: string) => {
              setReportFilterType(value);
              setIsRequested(false);
            }}
            title="Report Type"
            value={reportFilterType}
          />
          <Box marginTop={4}>
            <Text text={"Select Filter"} />
            <Autocomplete
              multiple={true}
              id="tags-standard"
              options={getFilterValuesByType()}
              // still useful for rendering selected values
              getOptionLabel={(option: any) => {
                const { showOptionsBy } = reportFilterConfig[reportFilterType];
                return option[showOptionsBy] || "";
              }}
              // isOptionEqualToValue={(option, value) => option._id === value._id}
              value={getSelectedFilterValues()}
              onChange={(e, values) => {
                setReportFilterValueIDs(
                  values.map((value) =>
                    reportFilterType === ReportFilterType.ADMIN
                      ? value.id
                      : value._id
                  )
                );
                setIsRequested(false);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  helperText={"Optional"}
                />
              )}
              renderOption={(props, option) => {
                const { showOptionsBy } = reportFilterConfig[reportFilterType];
                return (
                  <li {...props} key={option._id}>
                    {option[showOptionsBy]}
                  </li>
                );
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Box m={4}>
            <CustomButton
              title="Get Data"
              onClick={getAdminReport}
              // disabled={isRequested}
              loading={loading}
              loadingText={"Please wait..."}
            />
          </Box>
          <Box width={"100%"} display={"flex"} justifyContent={"center"}>
            {isRequested && (
              <Text
                text={`${reportFilterConfig[reportFilterType].label} report will be sent to you via email shortly.`}
                fontSize={16}
                fontWeight={600}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
