import React, { useState } from "react";
import { Autocomplete, Box, Collapse, TextField } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { CollectionPoint, VenueSection } from "../../types";
import { CustomInput } from "../../elements/input";
import { ConfirmationDailog } from "../../elements/confirmation-dialog";
import { Text } from "../../elements/text";
import { ExpandLessRounded, ExpandMoreRounded } from "@mui/icons-material";
import { VenueImageCard } from "./image-card";

interface CollectionPointProps {
  collectionPoint: CollectionPoint;
  index: number;
  deleteCollectionPoint: (_collectionPointId: string) => void;
  onChangeCollectionPoint: (
    _index: number,
    _updatedCollectionPoint: CollectionPoint
  ) => void;
  venueSections: VenueSection[];
}

const CollectionPointInput = ({
  collectionPoint,
  index,
  deleteCollectionPoint,
  onChangeCollectionPoint,
  venueSections,
}: CollectionPointProps) => {
  const getSelectedVenueSections = (): VenueSection[] => {
    const sections = venueSections;
    return sections.filter((section) => {
      if (collectionPoint.serviceableSectionsIds?.includes(section.id))
        return section;
    });
  };
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [expandImageControls, setExpandImageControls] = useState(false);

  return (
    <Box
      display={"flex"}
      mb={2}
      flexDirection={"column"}
      boxShadow={"0px 0px 4px #999"}
      px={4}
      py={2}
    >
      <ConfirmationDailog
        open={showConfirmationDialog}
        title={`Delete collection-point association?`}
        text={
          "This colleciton point is associated with one or more sections, are you sure you want to delete it?"
        }
        handleCancel={() => setShowConfirmationDialog(false)}
        handleContinue={() => {
          deleteCollectionPoint(collectionPoint.id);
          setShowConfirmationDialog(false);
        }}
      />
      <Box>
        <CustomInput
          autoFocus={true}
          value={collectionPoint.label}
          onChange={(value: string) => {
            collectionPoint.label = value;
            onChangeCollectionPoint(index, collectionPoint);
          }}
        />
      </Box>

      <Box my={2}>
        <Autocomplete
          multiple={true}
          id="tags-standard"
          options={venueSections}
          getOptionLabel={(section: VenueSection) => section.label}
          value={getSelectedVenueSections()}
          onChange={(e, values) => {
            const updatedServiceableSectionsIds = values.map(
              (value) => value.id
            );
            collectionPoint.serviceableSectionsIds =
              updatedServiceableSectionsIds;
            onChangeCollectionPoint(index, collectionPoint);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={"Serviceable Sections"}
            />
          )}
        />
      </Box>

      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        my={2}
      >
        <DeleteIcon
          sx={{
            cursor: "pointer",
          }}
          onClick={() => {
            if (
              collectionPoint.serviceableSectionsIds &&
              collectionPoint.serviceableSectionsIds?.length > 0
            ) {
              setShowConfirmationDialog(true);
            } else {
              deleteCollectionPoint(collectionPoint.id);
            }
          }}
        />
      </Box>
      <Box p={2} sx={{ backgroundColor: "#e8e8e8bb" }} borderRadius={"8px"}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          width={"100%"}
          sx={{ cursor: "pointer" }}
          onClick={() => setExpandImageControls(!expandImageControls)}
        >
          <Text text="Image (Optional)" fontSize={18} />
          {expandImageControls ? <ExpandLessRounded /> : <ExpandMoreRounded />}
        </Box>

        <Collapse in={expandImageControls} timeout={"auto"} unmountOnExit>
          <VenueImageCard
            previewImageSrc={collectionPoint.cpNavigationImageUrl}
            label={"CP Navigation"}
            toolTipLabel={
              "This is used for helping patron navigate to collection-point (CP Orders)"
            }
            resourceImageSrc={collectionPoint.cpNavigationImageUrl}
            onUploadImage={(url: string) => {
              const updatedCollectionPoint: CollectionPoint = {
                ...collectionPoint,
                cpNavigationImageUrl: url,
              };
              onChangeCollectionPoint(index, updatedCollectionPoint);
            }}
            resourceImageNote={
              "This is used for helping patron navigate to collection-point (CP Orders)"
            }
          />
        </Collapse>
      </Box>
    </Box>
  );
};

export default CollectionPointInput;
