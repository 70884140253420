import { Autocomplete, Box, Collapse, TextField } from "@mui/material";
import React, { useState } from "react";
import { VenueSection } from "../../types";
import { CustomInput } from "../../elements/input";
import {
  serviceMethodsTitleMap,
  validServiceMethods,
} from "../../constants/constants";
import DeleteIcon from "@mui/icons-material/Delete";
import { VenueImageCard } from "./image-card";
import { ExpandLessRounded, ExpandMoreRounded } from "@mui/icons-material";
import { Text } from "../../elements/text";

type SectionInputProps = {
  sections: VenueSection[];
  section: VenueSection;
  index: number;
  setSections: React.Dispatch<React.SetStateAction<VenueSection[]>>;
  onClickDelete: (_id: string) => void;
};

function SectionInput({
  sections,
  section,
  index,
  setSections,
  onClickDelete,
}: SectionInputProps) {
  const [expandImageControls, setExpandImageControls] = useState(false);

  return (
    <Box
      display={"flex"}
      mb={2}
      key={section.id}
      flexDirection={"column"}
      boxShadow={"0px 0px 4px #999"}
      px={4}
      py={2}
    >
      <Box>
        <CustomInput
          autoFocus={true}
          value={section.label}
          onChange={(value: string) => {
            const newSections: VenueSection[] = [...sections];
            newSections[index] = {
              label: value,
              id: sections[index].id,
              isClickAndCollect: sections[index].isClickAndCollect || false,
              serviceMethods: sections[index].serviceMethods,
            };

            setSections(newSections);
          }}
        />
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"space-evenly"}
        my={2}
      >
        <Autocomplete
          multiple={true}
          id="tags-standard"
          options={validServiceMethods}
          getOptionLabel={(serviceMethod: string) =>
            serviceMethodsTitleMap[serviceMethod]
          }
          value={section.serviceMethods}
          onChange={(e, values) => {
            const newSections: VenueSection[] = [...sections];
            newSections[index] = {
              label: sections[index].label,
              id: sections[index].id,
              isClickAndCollect: sections[index].isClickAndCollect,
              isDeleted: sections[index].isDeleted,
              serviceMethods: values,
            };
            setSections(newSections);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={"Service Methods"}
            />
          )}
        />
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          my={2}
        >
          <DeleteIcon
            sx={{
              cursor: "pointer",
            }}
            onClick={() => onClickDelete(section.id)}
          />
        </Box>

        <Box p={2} sx={{ backgroundColor: "#e8e8e8bb" }} borderRadius={"8px"}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            width={"100%"}
            sx={{ cursor: "pointer" }}
            onClick={() => setExpandImageControls(!expandImageControls)}
          >
            <Text text="Image (Optional)" fontSize={18} />
            {expandImageControls ? (
              <ExpandLessRounded />
            ) : (
              <ExpandMoreRounded />
            )}
          </Box>

          <Collapse in={expandImageControls} timeout={"auto"} unmountOnExit>
            <VenueImageCard
              previewImageSrc={section.cncNavigationImageUrl}
              label={"CNC Navigation"}
              toolTipLabel={
                "This is used for helping patron navigate to vendor (CNC Orders)"
              }
              resourceImageSrc={section.cncNavigationImageUrl}
              onUploadImage={(url: string) => {
                const newSections: VenueSection[] = [...sections];
                newSections[index] = {
                  label: section.label,
                  id: sections[index].id,
                  isClickAndCollect: sections[index].isClickAndCollect || false,
                  serviceMethods: sections[index].serviceMethods,
                  cncNavigationImageUrl: url,
                };
                setSections(newSections);
              }}
              resourceImageNote={
                "This is used for helping patron navigate to vendor (CNC Orders)"
              }
            />
          </Collapse>
        </Box>
      </Box>
    </Box>
  );
}

export default SectionInput;
